<script setup>
import { computed, inject } from "vue";
import { useRoute } from "vue-router";

const displayWidth = inject("displayWidth");
const chatService = inject("chatService");

const route = useRoute();

const onlyChatList = computed(
  () => displayWidth.value < 992 && !route.meta.chatDetail
);
const onlyChatDetail = computed(
  () => displayWidth.value < 992 && route.meta.chatDetail
);

chatService.listCommandMessages();
</script>

<template>
  <div class="chat-layout">
    <div
      class="chat-layout-side"
      :class="{ 'width-100': onlyChatList }"
      v-if="!onlyChatDetail"
    >
      <router-view name="sideMenu" v-if="!onlyChatList"></router-view>
      <router-view
        name="chatList"
        :class="{ 'width-100': onlyChatList }"
      ></router-view>
    </div>
    <div class="chat-layout-content" v-if="!onlyChatList">
      <router-view v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="route.params.id" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<style lang="scss">
.chat-layout {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: 0.3s all;
  .chat-layout-side {
    display: flex;
    flex-direction: row;
    background-color: var(--secondary-background-color);
  }
  .chat-layout-content {
    width: 100%;
    background-color: var(--default-background-color);
  }
  .chat-detail-sidebar {
    display: flex;
    flex-direction: column;
    .chat-detail-sidebar-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.625rem 1rem;
      border-right: 1px solid var(--secondary-background-color);
      background: linear-gradient(
        to bottom,
        var(--theme-primary-color-dark),
        transparent
      );
      border-bottom: 1px solid var(--secondary-background-color);
      .chat-detail-sidebar-head-title {
        line-height: 1.5rem;
        margin-bottom: 0;
        padding: 0.5rem;
      }
      .chat-detail-sidebar-head-left {
        flex-grow: 1;
      }
      .chat-detail-sidebar-head-right {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .chat-detail-sidebar-body {
      padding: 0.8rem;
    }
  }
}
</style>
